import React from "react"
import { graphql } from "gatsby"
import { pluck } from "ramda"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Tag from "../components/Tag/Tag"

import styles from "./tags.module.css"

const TagsPage = ({ data, location }) => {
  const { group } = data.allMdx
  const siteTitle = data.site.siteMetadata.title
  const tags = pluck("fieldValue", group)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="All topics"
        description="Search for articles by topic."
        keywords="all topics, all keycloak topics, all tags, all keycloak tags"
      />

      <main className={styles.main}>
        <ul className={styles.tagList}>
          {tags.map(tag => (
            <li key={tag} className={styles.tagItem}>
              <Tag tag={tag} />
            </li>
          ))}
        </ul>
      </main>
    </Layout>
  )
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags, limit: 2000) {
        fieldValue
      }
    }
  }
`
