import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { toKebabCase } from "ramda-extension/"

import styles from './tag.module.css'

const Tag = ({ tag }) => (
  <Link
    to={`/tags/${toKebabCase(tag)}/`}
    className={styles.tag}
    title={tag}
  >
    {tag}
  </Link>
)

Tag.propTypes = {
  tag: PropTypes.string.isRequired,
}

export default Tag
